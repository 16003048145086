@import "bootstrap-customizations";
// Required
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

// Optional
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_type";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_nav";
@import "~bootstrap/scss/_navbar";
@import "~bootstrap/scss/utilities/_flex";
@import "~bootstrap/scss/utilities/_spacing";
@import "~bootstrap/scss/utilities/_text";
@import "~bootstrap/scss/mixins/_grid-framework";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: sans-serif !important;
}

$primary: #0a347a;
$secondary: #1b52a0;

.navbar-brand {
  img.logo {
    height: 70px;
  }
}

@media (max-width: 360px) {
  img.logo {
    height: 50px !important;
  }
}

.navbar-saverr {
  padding: 15px 100px;
}

@media (max-width: 768px) {
  .navbar-saverr {
    padding: 15px 20px;
  }
}

button:focus {
  outline: 0px;
}

.toggler-click {
  font-size: 2rem;
  font-weight: bold;
  background-color: #f5f7ff;
  text-align: center;
  .navbar-nav .nav-link {
    color: $primary !important;
  }
  .navbar-nav .nav-link:hover {
    color: #0052d4 !important;
  }
}

.close {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  content: " ";
  height: 1.5em;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.navbar-light .navbar-nav .nav-link {
  color: $primary;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 20px;
  text-transform: uppercase;
  &:hover {
    color: #4a8ebc;
  }
}

.hero-section {
  margin-top: -130px;
  padding-top: 140px;
  padding-left: 100px;
  padding-right: 100px;
  background: url(../assets/img/header-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  h1 {
    font-weight: 900;
    color: $primary;
    font-size: 2.4rem;
    line-height: 1.5;
  }
  h2 {
    font-weight: 300;
    color: $primary;
    font-size: 1.8rem;
    line-height: 1.5;
  }
  .hero-section-image {
    img {
      width: 100%;
    }
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding-left: 10px;
    padding-right: 10px;
    h1 {
      font-weight: 900;
      font-size: 1.8rem;
    }
    h2 {
      font-weight: 300;
      font-size: 1.4rem;
    }
  }
}

.club-features {
  margin-top: -100px;
  padding: 180px 100px 50px 100px;
  background: url(../assets/img/club-feature-back.png);
  background-repeat: no-repeat;
  height: auto;
  background-size: 1300px;
  img.title-logo {
    height: 70px;
    margin-top: 30px;
  }
  h1 {
    font-weight: 900;
    color: $primary;
    font-size: 2.6rem;
    line-height: 1.5;
  }
  .club-feature-points {
    h1 {
      font-weight: 300;
      color: $secondary;
      font-size: 1.2rem;
      line-height: 1.2;
    }
    .features {
      img.title-logo {
        width: 60%;
        height: auto;
      }
      min-height: 300px;
      padding: 10px 50px;
    }
  }
}

@media (max-width: 768px) {
  .club-features {
    margin-top: 20px;
    background-size: 800px;
    padding: 50px 10px 30px 10px;
    h1 {
      font-weight: 900;
      padding-top: 10px;
      font-size: 2rem;
    }
    img.title-logo {
      height: 50px;
      margin-top: 30px;
    }

    .club-feature-points {
      .features {
        h1 {
          font-weight: 300;
          color: $secondary;
          font-size: 1.2rem;
        }
        .features {
          img.title-logo {
            width: 40% !important;
            height: auto;
          }
        }
        min-height: 260px;
      }
    }
  }
}

.our-product {
  background: url(../assets/img/how-it-works-step2-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -80px;
  height: auto;
  padding: 100px 100px;

  h1 {
    font-weight: 900;
    font-size: 2.6rem;
    color: $primary;
    text-transform: uppercase;
    line-height: 1.5;
  }
  .saverr-image {
    img {
      width: 100%;
    }
    padding: 20px;
  }
  .saverr-details {
    padding: 10px 40px;
    .step {
      padding: 10px 20px;
      h1 {
        font-weight: bold;
        color: $primary;
        font-size: 2.2rem;
        text-transform: uppercase;

        line-height: 1.5;
      }
      p {
        font-weight: 500;
        color: $secondary;
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .our-product {
    margin-top: 0px;
    padding: 10px 10px;
    h1 {
      font-size: 2rem;
    }
    .saverr-details {
      padding: 10px 10px;
      .step {
        padding: 10px 20px;
        h1 {
          font-size: 1.6rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

.link-your-card {
  padding: 20px 0px 0px 100px;

  h1 {
    text-transform: uppercase;
    font-weight: 800;
    color: $primary;
    font-size: 2.6rem;
    line-height: 1.5;
  }
  .hr-line {
    width: 20%;
    border: 1px solid #cccccc;
    margin: 0;
  }
  .link-your-card-image {
    img {
      width: 100%;
    }
    padding: 20px;
  }
  .link-your-card-details {
    padding-right: 40px;
    img.title-logo {
      height: 70px;
      margin-top: 10px;
    }
    .step {
      padding: 10px 20px;
      p {
        font-weight: 500;
        color: $secondary;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .link-your-card {
    padding: 40px 0 0 10px;
    h1 {
      font-size: 2rem;
    }
    .hr-line {
      width: 30%;
      border: 1px solid #cccccc;
    }
    .link-your-card-details {
      padding-right: 10px;
      .step {
        padding: 10px 20px;
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.link-your-card-would {
  padding: 0px 100px 30px 0px;
  h1 {
    text-transform: uppercase;
    font-weight: 800;
    color: $primary;
    font-size: 2.6rem;
    line-height: 1.5;
  }
  .hr-line {
    width: 20%;
    border: 1px solid #cccccc;
    margin: 0;
  }
  .link-your-card-image {
    img {
      width: 100%;
    }
    padding: 20px;
  }
  .link-your-card-details {
    padding-right: 40px;
    img.title-logo {
      height: 70px;
      margin-top: 10px;
    }
    .step {
      padding: 10px 20px;
      p {
        font-weight: 500;
        color: $secondary;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .link-your-card-would {
    padding: 0px 10px 30px 10px;
    h1 {
      font-size: 2rem;
    }
    .hr-line {
      width: 30%;
      border: 1px solid #cccccc;
    }
    .link-your-card-details {
      padding-right: 10px;

      .step {
        padding: 10px 20px;
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.talk-to-us {
  background: url(../assets/img/footer-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding: 100px 100px 40px 100px;
  margin-top: -150px;
  .talk-to-us-details {
    padding-top: 150px;
  }
  .saverr-logo {
    img.img-footer {
      width: 70%;
    }
  }
  img.img-contact {
    width: 300px;
    height: 300px;
    float: right;
    margin-bottom: -150px;
  }
  h1 {
    font-weight: bold;
    font-size: 2.8rem;
    color: $primary;
    line-height: 1.5;
  }
  h3 {
    font-size: 1.4rem;
    color: $secondary;
    line-height: 1.5;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 500;
    color: $secondary;
    line-height: 1.5;
    a {
      font-weight: 800 !important;
      color: #1a9cfe;
    }
  }
  .footer-steps {
    padding: 30px;
  }
  .steps {
    padding: 10px 20px;
    img {
      width: 40px;
      height: auto;
      float: left;
    }
    p {
      font-weight: 500;
      color: $secondary;
      font-size: 1.3rem;
      float: left !important;
    }
  }
  .footer-menu {
    .nav-link {
      color: #144266;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0.5rem;
    }
  }
  .social-icons {
    a.fa {
      float: right;
      padding: 10px;
      background: #1a9cfe;
      font-size: 1.5rem;
      color: white;
      width: 50px;
      border-radius: 50%;
      border: 2px solid #1a9cfe;
      text-align: center;
      text-decoration: none;
      margin: 5px 10px;
    }
    a.fa:hover {
      background: white;
      color: #1a9cfe;
    }
  }
}

.footer-section {
  .horizontal-row {
    border-top: 2px solid #e4e4e9;
  }

  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    vertical-align: middle;
    .footer-text {
      .vida-technologies {
        .vida-technologies-logo {
          img.img-footer {
            width: 100%;
          }
        }
      }
      padding: 0px 20px;
      border-left: 2px solid #e4e4e9;
      p {
        font-size: 16px;
        color: $secondary;
      }
    }
  }
}

@media (max-width: 768px) {
  .talk-to-us {
    margin-top: -100px;
    padding: 140px 10px 40px 10px;
    height: auto;
    .talk-to-us-details {
      padding-top: 50px;
    }
    .get-in-touch-img {
      img.img-contact {
        width: 250px;
        height: 250px;
        margin-bottom: -70px;
      }
    }
    h1 {
      font-weight: 900;
      font-size: 2rem;
    }
    h3 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.8rem;
      a {
        font-weight: 800 !important;
        color: #1a9cfe;
      }
    }
    .footer-steps {
      padding: 10px;
    }
    .steps {
      padding: 10px 10px;
      p {
        font-size: 1rem;
      }
    }
  }
  .footer-section {
    .footer {
      padding-top: 20px;
      padding-bottom: 20px;
      .saverr-logo {
        text-align: center;
        img.img-footer {
          width: 90%;
        }
      }
      .footer-text {
        .vida-technologies {
          .vida-technologies-logo{
            img.img-footer{
              width: 90px;
            }
          }
          .vida-technologies-text {
            padding: 0 5px;
          }
        }
        border-left: 1px solid #e4e4e9;
        padding: 0px 20px;

        p {
          font-size: 16px;
          color: $secondary;
        }
      }
    }
  }
}

@media (max-width: 547px) {
  .talk-to-us {
    background: #f3f6ff;
    margin-top: -10px;
    padding: 30px 10px 40px 10px;
    height: auto;
    .talk-to-us-details {
      padding-top: 80px;
    }
    .get-in-touch-img {
      text-align: center;
      img.img-contact {
        width: 250px;
        height: 250px;
        float: none;
      }
    }
    h1 {
      font-weight: 900;
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 1.5rem;
      a {
        font-weight: 800 !important;
        color: #1a9cfe;
      }
    }
    .footer-steps {
      padding: 10px;
    }
    .steps {
      padding: 10px 10px;
      img {
        padding-bottom: 10px;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
  .footer-menu {
      justify-content: center;
  }
  .footer-section {
    .footer {
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      .saverr-logo {
        text-align: center;
        img.img-footer {
          width: 50%;
        }
      }
      .footer-text {
        .vida-technologies {
          .vida-technologies-logo{
            text-align: center;
            img.img-footer{
              width: 120px;
            }
          }
          .vida-technologies-text {
            text-align: center;
            padding: 10px 10px;
          }
          padding: 10px;
        }
        border-left: 0;
        padding: 0px 20px;
        p {
          text-align: center;
          font-size: 14px;
          color: $secondary;
        }
      }
    }
  }
}
